import React from "react";
import lens from "../../assets/min_camera-lens-with-reflections_inverted.jpeg";

export default function MobileHeader() {
  return (
    <>
      {/* <div className="absolute z-10 w-auto min-w-full min-h-full max-w-none bg-cover bg-right bg-white bg-no-repeat bg-[linear-gradient(to_right,rgba(0,0,0,1),rgba(241,241,241,0)),url('https://ik.imagekit.io/ajuaxvsrja/min_changing-camera-lens-aperture-2021-08-28-21-07-08-utc.jpeg?updatedAt=1743230463810')]"></div> */}
      {/* <div className="absolute z-10 w-auto min-w-full min-h-full max-w-none bg-cover bg-right bg-white bg-no-repeat bg-[linear-gradient(to_right,rgba(0,0,0,1),rgba(241,241,241,0)),url('https://ik.imagekit.io/ajuaxvsrja/tr:q-50,f-auto/min_camera-lens-with-reflections_inverted-min.jpeg?updatedAt=1733648565324')]"></div> */}
      <div
        style={{
          backgroundImage: `linear-gradient(to right,rgba(0,0,0,1),rgba(241,241,241,0)),url(${lens})`,
          position: "absolute",
          zIndex: "10",
          minHeight: "100%",
          width: "auto",
          minWidth: "100%",
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right",
        }}
        // className={`min-w-full max-w-none absolute z-10 bg-cover bg-white bg-no-repeat bg-[linear-gradient(to_right,rgba(0,0,0,1),rgba(241,241,241,0))]`}
      ></div>
    </>
  );
}
