import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";

import { IoMdStar } from "react-icons/io";

import ReviewModal from "./reviewComponents/ReviewModal";

export default function ReviewProfile({ profile }) {
  const [show, setShow] = useState(false);
  const [reviews, setReviews] = useState([]);
  const review = (
    profile.reviewsPublishedRating / profile.reviewsPublishedNo
  ).toFixed(1);

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "reviews");
      const q = query(
        colRef,
        where("proID", "==", profile.user),
        where("adminPublish", "==", true),
        orderBy("shootDate", "desc")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      setReviews(docs);
      // console.log("docs: ", docs);
    })();
  }, []);

  return (
    <>
      {review > 0 && (
        <div>
          <div className="w-full">
            <div
              onClick={() => setShow(true)}
              className="cursor-pointer mx-auto text-center rounded-lg w-1/3 md:w-1/2 bg-white shadow-sm p-5"
            >
              <IoMdStar
                title="reviews"
                className="text-center inline-block text-3xl text-orange-400 hover:text-orange-400"
              />
              <span
                data-cy="portfolio-review"
                className="text-center font-bold text-lg inline-block align-bottom hover:underline"
              >
                {review}
              </span>
              {profile.reviewsPublishedNo > 1 ? (
                <p className="text-gray-400 text-sm w-full">
                  {profile.reviewsPublishedNo} reviews
                </p>
              ) : (
                <p className="text-gray-400 text-sm w-full">
                  {profile.reviewsPublishedNo} review
                </p>
              )}
            </div>
          </div>
          <div
            className={`${
              show === true ? "" : "hidden"
            } absolute top-0 left-0 bg-black w-screen h-full bg-opacity-80`}
            onClick={() => closeModal}
          ></div>
          <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className={`${
              show === true ? "" : "hidden"
            } absolute left-0 md:left-10 top-5 md:top-10 w-full md:w-4/5 z-10 p-4`}
          >
            <div className="relative w-full max-w-2xl max-h-full">
              {/* <!-- Modal content --> */}
              <div className="bg-white rounded-lg shadow">
                {/* <!-- Modal header --> */}
                <div className="flex items-start justify-between p-4 border-b rounded-t">
                  <h3 className="text-xl font-semibold text-gray-900">
                    Reviews: {profile.name}
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                    data-modal-hide="defaultModal"
                  >
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={closeModal}
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                {/* <!-- Modal body --> */}
                <div className="p-6 space-y-6">
                  {/* <p className="text-base leading-relaxed text-gray-500">
                    Ask {profile.name} a question about their photography and/or
                    videography services.
                    <br />
                    <br />
                    Only BraThula will have your contact details. If you have
                    any questions, please email: hello@brathula.com
                  </p> */}
                  <ReviewModal reviews={reviews} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
