import React, { useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Hits, Pagination } from "react-instantsearch";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router";
import CustomSearchBox from "./searchComponents/CustomSearchBox";
// import { IoIosHeart } from "react-icons/io";
import { IoMdStar } from "react-icons/io";
import avatar from "../../assets/Avatar/photo.svg";

const searchClient = algoliasearch(
  "TM8M0Q0F1D",
  "a446dcc62f0e740746acbb0eeafba90f"
);

const Hit = ({ hit }) => {
  const navigate = useNavigate();
  const baseURL = hit.imageURL;
  const review = (hit.reviewsPublishedRating / hit.reviewsPublishedNo).toFixed(
    1
  );

  const hostname = window.location.hostname;

  useEffect(() => {
    if (hostname === "localhost") {
      console.log(hit.name, hit);
    }
  }, [hit, hostname]);

  // useEffect(() => {
  //   console.log("Review Rating:", hit.reviewsPublishedRating);
  //   console.log("Review Publishing", hit.reviewsPublishedNo);
  //   console.log(review);
  // }, []);

  // useEffect(() => {
  //   if (hit.user === "fMeOVV9UMxSx5sGWtVYwlZ2NViJ3") {
  //     console.log(
  //       "User",
  //       hit.name,
  //       hit.region,
  //       hit.photographer_rate,
  //       hit.imageURL,
  //       hit.profileImageURL
  //     );
  //   }
  // }, [hit, baseURL]);

  let imageKitUrl = "";
  let img225;
  let img300;
  let img350;
  let img640;
  if (baseURL.startsWith("https://storage.googleapis.com/")) {
    // imageKitUrl = "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/".concat(
    //   baseURL
    // );
    imageKitUrl = baseURL.replace(
      "https://storage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/"
    );
    img225 = baseURL.replace(
      "https://storage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-225/"
    );
    img300 = baseURL.replace(
      "https://storage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-300/"
    );
    img350 = baseURL.replace(
      "https://storage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-350/"
    );
    img640 = baseURL.replace(
      "https://storage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-640/"
    );
  } else if (baseURL.startsWith("https://firebasestorage.googleapis.com")) {
    imageKitUrl = baseURL.replace(
      "https://firebasestorage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/"
    );
    img225 = baseURL.replace(
      "https://firebasestorage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-225/"
    );
    img300 = baseURL.replace(
      "https://firebasestorage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-300/"
    );
    img350 = baseURL.replace(
      "https://firebasestorage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-350/"
    );
    img640 = baseURL.replace(
      "https://firebasestorage.googleapis.com/",
      "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto,w-640/"
    );
  }
  return (
    <div
      className="relative w-full h-full hover:shadow-2xl shadow-white"
      onClick={() => navigate(`/portfolio/${hit?.user}`)}
    >
      <div className="flex absolute left-0 bottom-0 z-10 items-center w-full bg-white bg-opacity-90 ">
        <div className="ml-2">
          <img
            // src={`https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/${hit?.profileImageURL}`}
            src={
              hit.profileImageURL === ""
                ? avatar
                : `https://ik.imagekit.io/ajuaxvsrja/tr:q-10,f-auto/${hit.profileImageURL}`
            }
            alt="profileURL"
            className="top-1/2 w-12 h-12 ring-2 ring-orange-400 object-cover rounded-full p-1 align-middle"
          />
        </div>
        <div className="text-[#8f8f8f] ml-2 font-bold">
          <div>{hit?.name}</div>

          <div className="">{hit?.region}</div>
          <div className="">R{hit?.photographer_rate}/hr</div>
          {review > 0 && (
            <div className="absolute bottom-1 right-1">
              <IoMdStar
                title="reviews"
                className="inline-block text-3xl text-orange-400"
              />
              <span className="inline-block align-bottom">{review}</span>
            </div>
          )}
        </div>
      </div>
      <img
        data-cy="algolia-hit"
        className="relative w-full h-full object-contain object-center"
        src={imageKitUrl}
        srcSet={`${img225} 225w, ${img300} 300w, ${img350} 350w, ${img640} 640w`}
        sizes="(max-width: 400px) 100vw, (max-width: 700px) 50vw, (max-width: 900px) 33vw, 450px"
        alt={hit.imageName}
      />
    </div>
  );
};

export default function Search() {
  const initialQuery = "graduation";

  return (
    <>
      {" "}
      <div className="bg-white py-5">
        <p
          data-cy="search-heading"
          className="text-black text-center font-light p-2 text-3xl md:text-4xl"
        >
          Search through images...
        </p>
        <InstantSearch
          searchClient={searchClient}
          indexName="searchImages"
          insights={true}
          routing={true}
          initialUiState={{
            searchImages: {
              query: initialQuery,
            },
          }}
        >
          <CustomSearchBox initialQuery={initialQuery} />
          <Hits
            hitComponent={Hit}
            id="hits"
            classNames={{
              root: "p-0 my-2",
              list: "grid lg:grid-cols-4 md:grid-cols-2 gap-4",
              item: "w-full hover:cursor-pointer shadow ",
            }}
          />
          <Pagination
            totalPages={4}
            showFirst={false}
            showLast={false}
            // scrollTo={"#hits"}
            classNames={{
              root: "m-5 flex",
              list: "flex mx-auto",
              item: "m-2 text-black text-lg",
            }}
          />
        </InstantSearch>
      </div>
    </>
  );
}
