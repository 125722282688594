import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { Helmet } from "react-helmet-async";
// import { BsFillCameraFill, BsFillCameraReelsFill } from "react-icons/bs";
// import { useNavigate } from "react-router-dom";
// import { Carousel } from "@material-tailwind/react";

// import avatar from "../assets/Avatar/photo.svg";
import ImagesGallery from "../components/packageShowListComponents/ImagesGallery";
import Details from "../components/packageShowListComponents/Details";
// MdOutlinePhotoLibrary

export default function Packages() {
  const [packages, setPackages] = useState([]);
  const [packagesDocsReady, setPackageDocsReady] = useState(false);
  const [packagesId, setPackagesId] = useState([]);
  const hostname = window.location.hostname;
  // const navigate = useNavigate();

  // const packagesLocation = query(collection(db, `packages/${user}`))
  useEffect(() => {
    (async () => {
      const colRef = collection(db, "packages");
      const q = query(
        colRef,
        where("adminPublish", "==", "true"),
        where("userPublish", "==", "true"),
        where("images", "!=", "[]")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      // const proIDs = snapshots.docs.map((doc) => doc.data().user);

      const docsId = snapshots.docs.map((doc) => doc.id);
      setPackagesId(docsId);
      setPackages(docs);
      // setUserIDs(new Set(proIDs));
      setPackageDocsReady(true);
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Photography & Videography Packages and Prices for 2025</title>
        <meta
          name="description"
          content="Photography and Videography packages and prices for 2025"
        />
        <link rel="canonical" href="https://www.brathula.com/packages" />
      </Helmet>
      <h1 className="text-center md:text-2x text-xl md:py-10 py-5 bg-gray-100">
        Photography & Videography Packages and Prices for 2025
      </h1>
      {packages && (
        <div data-cy="main-div" className="w-full bg-gray-100 px-2">
          <div className="mx-auto grid w-full lg:w-10/12 flex-col gap-2 md:grid-cols-2">
            {packages.map((packageSolo, index) => {
              if (hostname === "localhost") {
                console.log("Package ", index + 1, packageSolo);
              }

              return (
                <>
                  <div
                    data-cy="packages-divs"
                    className="h-96 divide-y divide-gray-600 z-0 relative bg-white shadow rounded-md hover:border-orange-400"
                    // onClick={() => navigate(`/package/${packagesId[index]}`)}
                    key={index}
                  >
                    <ImagesGallery
                      packageSolo={packageSolo}
                      packagesDocsReady={packagesDocsReady}
                      packageId={packagesId[index]}
                    />
                    <Details
                      packageSolo={packageSolo}
                      packagesDocsReady={packagesDocsReady}
                      packageId={packagesId[index]}
                    />
                    {/* <div className="bg-yellow-400 h-4/5">Image</div> */}
                    {/* <div className="bg-pink-400 h-1/5">Details</div> */}
                  </div>
                  {/* <Carousel loop={true} autoplay={true} className="rounded-xl">
                    <img
                      src="https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                      alt="1"
                      className="h-full w-full object-cover object-center"
                    />
                    <img
                      src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                      alt="2"
                      className="h-full w-full object-cover object-center"
                    />
                    <img
                      src="https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80"
                      alt="3"
                      className="h-full w-full object-cover object-center"
                    />
                  </Carousel> */}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
