import { useState, useEffect } from "react";
// import { ref, listAll, getDownloadURL } from "firebase/storage";
import { db } from "../../../firebase";
import { collection, where, onSnapshot, query } from "firebase/firestore";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import FsLightbox from "fslightbox-react";

export default function ImageGallery({ profile }) {
  // const imageListRef = useRef(ref(storage, `images/${profile.user}`));
  const [imageList, setImageList] = useState([]);

  // const [imageIDs, setImageIDs] = useState([]);
  // const [imageURLs, setImageURLs] = useState([]);
  const [lightBox, setLightBox] = useState([]);
  const [toggler, setToggler] = useState(false);

  const hostname = window.location.hostname;

  useEffect(() => {
    const colRef = collection(db, "images");
    const q = query(
      colRef,
      where("user", "==", profile.user),
      where("adminPublish", "==", true)
    );

    onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs;
      // setImageIDs(docs.map((doc) => doc.id));
      setImageList(docs.map((doc) => doc.data()));
      setLightBox(docs.map((doc) => doc.data().imageLinks.imageKitUrl50));
    });
  }, [profile]);

  useEffect(() => {
    if (hostname === "localhost") {
      console.log("imageList: ", imageList);
      console.log("lightbox: ", lightBox);
    }
  }, [imageList, lightBox, hostname]);

  return (
    <>
      {" "}
      <div className="mb-2">
        <button
          className="bg-white mx-auto p-2"
          onClick={() => setToggler(!toggler)}
        >
          Open Slideshow
        </button>
        <FsLightbox toggler={toggler} sources={lightBox} />
      </div>
      <div className="-m-1 flex flex-wrap md:-m-2">
        {imageList &&
          imageList.map((image, index) => {
            return (
              <div
                key={index}
                className="relative flex w-full md:w-1/4 sm:w-1/2 flex-wrap"
              >
                <div key={index} className="w-full h-full p-1">
                  {/* <img
                    src={url}
                    alt={`photographer gallery-${index}`}
                    className="block w-full h-full object-contain object-center shadow-xl"
                    loading="lazy"
                    key={url}
                  /> */}
                  <LazyLoadImage
                    alt={`photographer gallery-${index}`}
                    src={image.imageLinks.imageKitUrl50}
                    key={image}
                    loading="lazy"
                    className="block w-full h-full object-contain object-center shadow-xl"
                    effect="blur"
                    // width={image.imageDetails.width}
                    // height={image.imageDetails.height}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}
